import { TempAssetEvent } from '../locgMachine.types'
import { deleteTempAsset } from '../services/deleteTempAsset'
import { fromPromise, setup } from 'xstate'
import { uploadTempAsset } from '../services/uploadTempAsset'

export const tempAssetMachine = setup({
  types: {
    context: {} as Record<never, never>,
    events: {} as TempAssetEvent
  },
  actors: {
    TempAssetUploader: fromPromise(
      async ({ input }: any) => await uploadTempAsset(input.context, input.event)
    ),
    TempAssetDeleter: fromPromise(
      async ({ input }: any) => await deleteTempAsset(input.context, input.event)
    )
  }
}).createMachine({
  id: 'TempAssetMachine',
  initial: 'idle',
  context: {},
  states: {
    idle: {
      on: {
        UPLOAD_TEMP_ASSET: {
          target: 'uploading'
        },
        DELETE_TEMP_ASSET: {
          target: 'deleting'
        }
      }
    },
    uploading: {
      invoke: {
        id: 'TempAssetUploader',
        src: 'TempAssetUploader',
        input: ({ context, event }) => {
          return { context, event }
        },
        onDone: 'idle',
        onError: {
          target: 'idle',
          actions: () => console.error('error uploading logo')
        }
      }
    },
    deleting: {
      invoke: {
        id: 'TempAssetDeleter',
        src: 'TempAssetDeleter',
        input: ({ context, event }) => {
          return { context, event }
        },
        onDone: 'idle',
        onError: {
          target: 'idle',
          actions: () => console.error('error deleting logo')
        }
      }
    }
  }
})
