import isUrl, { IsURLOptions } from 'validator/lib/isURL'

export const isValidUrl = (url: string, options: IsURLOptions = {}): boolean => {
  const length = url.length
  const str1 = url.substring(0, length / 2)
  const str2 = url.substring(length / 2, length)
  const isUrlDoubleCopy = str1 === str2
  const isValid = isUrl(url, { allow_underscores: true, ...options }) && !isUrlDoubleCopy
  let isDomainRu = false
  try {
    const hostname = new URL(url.startsWith('http') ? url : `https://${url}`).hostname
    isDomainRu =
      hostname.endsWith('.ru') ||
      hostname.endsWith('.рф') ||
      hostname.endsWith('.su') ||
      hostname.endsWith('.xn--p1ai')
    // .xn--p1ai is the punycode for .рф
    //.рф === rf (russian federation)
  } catch (e) {
    console.error('Error', e)
    isDomainRu = false
  }

  return isValid && !isDomainRu
}
