/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  CodeConfigurationField,
  LandingPageDestination
} from '@dtx-company/inter-app/src/types/flowcode'
import { FcShape } from '@dtx-company/flowcode-generator-browser'
import { LOGGED_OUT_GENERATOR_COLORS } from '../../components/Homepage/SimplifiedDesktop/constants'
import { LocgContext } from './locgMachine.types'
import { LoggedOutGeneratorSection } from '../../components/LoggedOutCodeGeneratorV2/LoggedOutCodeGenerator.types'
import {
  RIM_TEXT_CIRCLE_MAX_LENGTH,
  RIM_TEXT_SQUARE_MAX_LENGTH
} from '../../components/LoggedOutCodeGeneratorV2/GeneratorSections/CodeDesign/FrameAndText/constants'
import { SCROLL_CONTAINER_ID } from '../../components/LoggedOutCodeGeneratorV2/LoggedOutCodeGenerator.constants'
import { assign, forwardTo, fromPromise, setup } from 'xstate'
import { authFormMachine } from './authForm/authFormMachine'
import { scrollWithinContainer } from '@dtx-company/true-common/src/utils/scrolling/scrollWithinContainer'
import { tempAssetMachine } from './tempAsset/tempAssetMachine'
import { validateScanDestination } from './services/validateScanDestination'

export const createLocgMachine = ({ isMobile }: { isMobile?: boolean }) =>
  setup({
    types: {
      context: {} as LocgContext,
      events: {} as any
    },
    actors: {
      scanDestinationValidator: fromPromise(async ({ input }: any) => {
        const res = await validateScanDestination(input.context, input.event)
        return res
      }),
      authFormMachine: authFormMachine,
      tempAssetMachine: tempAssetMachine
    },
    actions: {
      setProgress: assign(({ context, event }) => ({
        ...context,
        progress: event.progress
      })),
      /**
       * Basic assign action to update context when a field value changes
       */
      onChange: assign(({ context, event }) => {
        const newContext = {
          ...context,
          values: {
            ...context.values,
            [event.key]: event.value
          },
          errors: {
            ...context.errors,
            [event.key]: undefined
          }
        }

        // Reduce top rim text length when user switches shape
        if (event.key === CodeConfigurationField.SHAPE) {
          if (newContext.values[CodeConfigurationField.TOP_RIM_TEXT]) {
            const maxLength =
              newContext.values.shape === FcShape.CIRCLE
                ? RIM_TEXT_CIRCLE_MAX_LENGTH
                : RIM_TEXT_SQUARE_MAX_LENGTH
            newContext.values[CodeConfigurationField.TOP_RIM_TEXT] = newContext.values[
              CodeConfigurationField.TOP_RIM_TEXT
            ].substring(0, maxLength)
          }
          if (newContext.values[CodeConfigurationField.BOTTOM_RIM_TEXT]) {
            const maxLength =
              newContext.values.shape === FcShape.CIRCLE
                ? RIM_TEXT_CIRCLE_MAX_LENGTH
                : RIM_TEXT_SQUARE_MAX_LENGTH
            newContext.values[CodeConfigurationField.BOTTOM_RIM_TEXT] = newContext.values[
              CodeConfigurationField.BOTTOM_RIM_TEXT
            ].substring(0, maxLength)
          }
        }

        if (event.key === CodeConfigurationField.SCAN_DESTINATION_TYPE) {
          /**
           * Reset scan destination, subject, and body when scan destination type changes
           */
          newContext.values[CodeConfigurationField.SCAN_DESTINATION] = ''
          newContext.values[CodeConfigurationField.SUBJECT] = ''
          newContext.values[CodeConfigurationField.BODY] = ''
          newContext.values[CodeConfigurationField.FILE] = undefined

          /**
           * Reset errors for scan destination, subject, body, and file
           * when scan destination type changes
           */
          newContext.errors[CodeConfigurationField.SCAN_DESTINATION] = undefined
          newContext.errors[CodeConfigurationField.SUBJECT] = undefined
          newContext.errors[CodeConfigurationField.BODY] = undefined
          newContext.errors[CodeConfigurationField.FILE] = undefined
        }

        return newContext
      }),
      setError: assign(({ context, event }) => {
        return {
          ...context,
          errors: {
            ...context.errors,
            ...event.error
          }
        }
      }),
      scrollTo: ({ event }) => {
        let target: string | undefined
        if ('target' in event && typeof event.target === 'string') {
          target = event.target
        } else if (
          'data' in event &&
          'target' in event.data &&
          typeof event.data?.target === 'string'
        ) {
          target = event.data.target
        }

        if (target) {
          // Scroll to target element after current event loop
          setTimeout(() => {
            scrollWithinContainer({
              containerId: SCROLL_CONTAINER_ID,
              elementId: target as string
            })
          }, 0)
        }
      }
    }
  }).createMachine({
    id: 'LOCG',
    initial: isMobile ? 'codeDesign' : 'scanDestination',
    context: {
      locale: 'en-US',
      progress: 0 / 12,
      values: {
        [CodeConfigurationField.SCAN_DESTINATION_TYPE]: LandingPageDestination.URL,
        [CodeConfigurationField.SCAN_DESTINATION]: '',
        [CodeConfigurationField.REGION_CODE]: 'US',
        [CodeConfigurationField.SUBJECT]: '',
        [CodeConfigurationField.BODY]: '',
        [CodeConfigurationField.FILE]: undefined,
        [CodeConfigurationField.LOGO]: undefined,
        [CodeConfigurationField.COLOR]: LOGGED_OUT_GENERATOR_COLORS[0],
        [CodeConfigurationField.SHAPE]: FcShape.CIRCLE,
        [CodeConfigurationField.AUTOGENERATED_OPTIONS]: undefined,
        [CodeConfigurationField.FRAME]: false,
        [CodeConfigurationField.TOP_RIM_TEXT]: undefined,
        [CodeConfigurationField.BOTTOM_RIM_TEXT]: undefined,
        ...(isMobile && {
          [CodeConfigurationField.FRAME]: true,
          [CodeConfigurationField.TOP_RIM_TEXT]: 'SCAN HERE',
          [CodeConfigurationField.BOTTOM_RIM_TEXT]: 'SCAN HERE'
        })
      },
      errors: {}
    },
    on: {
      SET_LOCALE: {
        actions: assign(({ context, event }) => ({
          ...context,
          locale: event.locale
        }))
      },
      CHANGE: {
        actions: ['onChange']
      },
      [LoggedOutGeneratorSection.CodeDesign]: {
        target: '#LOCG.codeDesign'
      },
      [LoggedOutGeneratorSection.ScanDestination]: {
        target: '#LOCG.scanDestination'
      },
      [LoggedOutGeneratorSection.SignUp]: {
        target: '#LOCG.auth'
      }
    },
    states: {
      scanDestination: {
        description: 'Select your scan destination',
        initial: 'editing',
        entry: [
          assign(({ context }) => ({
            ...context,
            progress: 4 / 12
          }))
        ],
        states: {
          editing: {
            on: {
              CHANGE: {
                actions: ['onChange']
              },
              BLUR: {
                target: 'validatingOnBlur'
              },
              SET_ERROR: {
                actions: ['setError']
              },
              NEXT: {
                target: 'validating'
              },
              DOWNLOAD_CODE: {
                target: 'validatingAuth'
              },
              UPLOAD_TEMP_ASSET: {
                actions: forwardTo('scan-destination-temp-asset-machine')
              },
              DELETE_TEMP_ASSET: {
                actions: forwardTo('scan-destination-temp-asset-machine')
              }
            },
            invoke: {
              id: 'scan-destination-temp-asset-machine',
              src: 'tempAssetMachine'
            }
          },
          validating: {
            invoke: {
              id: 'scan-destination-validator',
              src: 'scanDestinationValidator',
              input: ({ context, event }) => ({ context, event }),
              onDone: {
                target: '#LOCG.codeDesign',
                actions: 'scrollTo'
              },
              onError: {
                target: 'editing',
                actions: 'setError'
              }
            }
          },

          validatingOnBlur: {
            invoke: {
              id: 'scan-destination-validator',
              src: 'scanDestinationValidator',
              input: ({ context, event }) => {
                return { context, event }
              },
              onDone: {
                target: 'editing'
              },
              onError: {
                target: 'editing',
                actions: 'setError'
              }
            }
          },
          validatingAuth: {
            invoke: {
              id: 'auth-validator',
              src: 'scanDestinationValidator',
              input: ({ context, event }) => {
                return { context, event }
              },
              onDone: {
                target: '#LOCG.auth',
                actions: 'scrollTo'
              },
              onError: {
                target: 'editing',
                actions: 'setError'
              }
            }
          }
        }
      },
      codeDesign: {
        description: 'Design your code',
        initial: 'editing',
        states: {
          editing: {
            on: {
              CHANGE: {
                actions: ['onChange']
              },
              SET_PROGRESS: {
                actions: ['setProgress']
              },
              DOWNLOAD_CODE: {
                target: '#LOCG.auth'
              }
            },
            initial: 'color',
            states: {
              color: {
                entry: [
                  assign(context => ({
                    ...context,
                    progress: 5 / 12
                  }))
                ],
                on: {
                  CHANGE: {
                    actions: ['onChange']
                  },
                  NEXT: 'shape'
                }
              },
              shape: {
                entry: [
                  assign(context => ({
                    ...context,
                    progress: 6 / 12
                  }))
                ],
                on: {
                  CHANGE: {
                    actions: ['onChange']
                  },
                  NEXT: 'frameAndText'
                }
              },
              frameAndText: {
                entry: [
                  assign(context => ({
                    ...context,
                    progress: 7 / 12
                  }))
                ],
                on: {
                  CHANGE: {
                    actions: ['onChange']
                  },
                  NEXT: 'logo'
                }
              },
              logo: {
                entry: [
                  assign(context => ({
                    ...context,
                    progress: 8 / 12
                  }))
                ],
                on: {
                  CHANGE: {
                    actions: ['onChange']
                  },
                  UPLOAD_TEMP_ASSET: {
                    actions: forwardTo('logo-temp-asset-machine')
                  },
                  DELETE_TEMP_ASSET: {
                    actions: forwardTo('logo-temp-asset-machine')
                  }
                },
                invoke: {
                  id: 'logo-temp-asset-machine',
                  src: 'tempAssetMachine'
                }
              }
            }
          },

          complete: {
            type: 'final'
          }
        },
        on: {
          BACK: {
            target: 'scanDestination'
          },
          DOWNLOAD_CODE: {
            target: 'auth'
          },
          [LoggedOutGeneratorSection.ColorSelector]: {
            target: '#LOCG.codeDesign.editing.color',
            actions: ['scrollTo']
          },
          [LoggedOutGeneratorSection.ShapeSelector]: {
            target: '#LOCG.codeDesign.editing.shape',
            actions: ['scrollTo']
          },
          [LoggedOutGeneratorSection.FrameAndText]: {
            target: '#LOCG.codeDesign.editing.frameAndText',
            actions: ['scrollTo']
          },
          [LoggedOutGeneratorSection.Logo]: {
            target: '#LOCG.codeDesign.editing.logo',
            actions: ['scrollTo']
          },
          [LoggedOutGeneratorSection.SignUp]: {
            target: '#LOCG.auth',
            actions: ['scrollTo']
          }
        },
        onDone: {
          target: 'auth'
        }
      },
      auth: {
        description: 'Sign up or sign in to download code',
        entry: [
          assign(context => ({
            ...context,
            progress: 12 / 12
          }))
        ],
        invoke: {
          id: 'AuthMachine',
          src: 'authFormMachine',
          onDone: 'downloading'
        },
        on: {
          BACK: {
            target: 'codeDesign',
            actions: ['scrollTo']
          }
        },
        onDone: 'downloading'
      },

      downloading: {
        description: 'Download your code',
        on: {
          DOWNLOADED: 'complete'
        }
      },

      complete: {
        type: 'final'
      }
    }
  })
