import { LoggedOutGeneratorSection } from '@app/code/src/components/LoggedOutCodeGeneratorV2/LoggedOutCodeGenerator.types'
import { TRANSITION_DURATION } from '@app/code/src/components/LoggedOutCodeGeneratorV2/LoggedOutCodeGenerator.constants'
import { sendParent, setup } from 'xstate'

export const authFormMachine = setup({
  types: {
    events: {} as {
      type: 'SUBMIT' | 'SUCCESS' | 'FAILURE' | 'BACK'
    }
  },
  actions: {
    focusFirstInput: () => {
      setTimeout(() => {
        document.querySelector<HTMLInputElement>('input[name="email"]')?.focus()
      }, TRANSITION_DURATION)
    },
    onSuccess: () => {
      console.info('authFormMachine - onSuccess')
    },
    onFailure: () => {
      console.info('authFormMachine - onFailure')
    },
    onSubmit: () => {
      console.info('authFormMachine - onSubmit')
    }
  }
}).createMachine({
  id: 'AuthForm',
  initial: 'editing',
  states: {
    editing: {
      entry: ['focusFirstInput'],
      on: {
        SUBMIT: {
          target: 'submitting',
          actions: ['onSubmit']
        },
        BACK: {
          actions: sendParent({ type: LoggedOutGeneratorSection.CodeDesign })
        }
      }
    },

    submitting: {
      on: {
        SUCCESS: {
          target: 'signedIn',
          actions: ['onSuccess']
        },
        FAILURE: {
          target: 'editing',
          actions: ['onFailure']
        }
      }
    },

    signedIn: {
      type: 'final'
    }
  }
})
