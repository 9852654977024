import { IncomingMessage } from 'http'
import { ParsedUrlQuery } from 'querystring'
import { SessionAndOptimizelyValues } from '../types'
import { createSessionId } from '@dtx-company/inter-app/src/utils/optimizely'

export async function getInitialSessionAndOptimizelyValues(
  req: IncomingMessage | undefined,
  query: ParsedUrlQuery | undefined
): Promise<SessionAndOptimizelyValues | Record<string, never>> {
  const {
    getOptimizelyDatafileHash,
    fetchOptimizelyDatafile,
    getExperimentOverrides,
    getAttributes,
    getLocation
    // prevent the browser side _app bundle from picking up these server side imports (140k)
  } = await import('@dtx-company/inter-app/src/utils/optimizely')

  const requestUrl = req?.url ?? ''

  const optimizelyDatafileHash = getOptimizelyDatafileHash(req)

  const datafile = await fetchOptimizelyDatafile(optimizelyDatafileHash)

  // getInitialProps is called to render x.html and _next/data/dev/x.json
  // we exclude these objects from the JSON response
  // the App should preserve the values originally presented in the HTML response
  const isHtmlRequest = !requestUrl.startsWith('/_next/data/')

  if (!isHtmlRequest) {
    return {}
  }

  return {
    // the properties, `session` and `location` are mutated in the Cloudflare worker
    // the sessionId should only be used for the Optimizely client to render SSR
    // and the location should only be used within the context of the browser
    session: {
      sessionId: createSessionId(req),
      location: getLocation(req)
    },
    optimizely: {
      datafile,
      overrides: getExperimentOverrides(query ?? {}),
      attributes: getAttributes(req, query)
    }
  }
}
//
