export const enum PlanType {
  FREE = 'FREE',
  PRO = 'PRO',
  PRO_PLUS_ORG = 'PRO+ORG',
  PRO_FLEX = 'PRO_FLEX',
  PILOT = 'PILOT',
  ENTERPRISE = 'ENTERPRISE'
}

export const enum PlanDisplayNames {
  BASIC = 'Basic',
  PRO = 'Pro',
  PRO_PLUS_ORG = 'Pro Plus',
  PRO_FLEX = 'Growth',
  PILOT = 'Trial',
  ENTERPRISE = 'Enterprise'
}

export const lowerPlans: Record<PlanType, PlanType[]> = {
  [PlanType.FREE]: [],
  [PlanType.PILOT]: [],
  [PlanType.PRO]: [PlanType.FREE, PlanType.PILOT],
  [PlanType.PRO_PLUS_ORG]: [PlanType.FREE, PlanType.PILOT, PlanType.PRO],
  [PlanType.PRO_FLEX]: [PlanType.FREE, PlanType.PILOT, PlanType.PRO, PlanType.PRO_PLUS_ORG],
  [PlanType.ENTERPRISE]: [
    PlanType.FREE,
    PlanType.PILOT,
    PlanType.PRO,
    PlanType.PRO_PLUS_ORG,
    PlanType.PRO_FLEX
  ]
}
