import { ActorRefFrom } from 'xstate'
import { FC, ReactNode, createContext } from 'react'
import { createLocgMachine } from '@app/code/src/machines/locg/locgMachine'
import { useSetupLocgService } from '@app/code/src/machines/locg/useSetupLocgService'

export interface GlobalXStateContextType {
  locgService: ActorRefFrom<typeof createLocgMachine>
}

export const GlobalXStateContext = createContext<GlobalXStateContextType | undefined>(undefined)

export const XStateProvider: FC<{ children: ReactNode; isMobile?: boolean }> = ({
  children,
  isMobile
}) => {
  const locgService = useSetupLocgService({ isMobile })

  return (
    <GlobalXStateContext.Provider value={{ locgService }}>{children}</GlobalXStateContext.Provider>
  )
}
