import { ActorRefFrom } from 'xstate'
import { createLocgMachine } from './locgMachine'
import { useActorRef } from '@xstate/react'
import { useMemo } from 'react'
import { useOnce } from '@dtx-company/true-common/src/hooks/useOnce'
import useTranslation from 'next-translate/useTranslation'

export const useSetupLocgService = ({
  isMobile
}: {
  isMobile?: boolean
}): ActorRefFrom<typeof createLocgMachine> => {
  const { lang } = useTranslation()

  const machine = useMemo(() => createLocgMachine({ isMobile }), [isMobile])

  const locgService = useActorRef(machine)

  useOnce(() => {
    // Pass the locale to the state machines to make it available in the context
    // so next-translate/getT can be used outside of React components
    if (lang) locgService.send({ type: 'SET_LOCALE', locale: lang })
  })

  return locgService
}
